@font-face {
  font-family: 'Concept-Studio-Icons';
  src:  url('fonts/Concept-Studio-Icons.eot?1xtqc1');
  src:  url('fonts/Concept-Studio-Icons.eot?1xtqc1#iefix') format('embedded-opentype'),
    url('fonts/Concept-Studio-Icons.woff2?1xtqc1') format('woff2'),
    url('fonts/Concept-Studio-Icons.ttf?1xtqc1') format('truetype'),
    url('fonts/Concept-Studio-Icons.woff?1xtqc1') format('woff'),
    url('fonts/Concept-Studio-Icons.svg?1xtqc1#Concept-Studio-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="cs-"], [class*=" cs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Concept-Studio-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-quote:before {
  content: "\e90a";
}
.cs-behance:before {
  content: "\e909";
}
.cs-clutch:before {
  content: "\e907";
}
.cs-vimeo:before {
  content: "\e908";
}
.cs-book-reader:before {
  content: "\e906";
}
.cs-facebook:before {
  content: "\e903";
}
.cs-linkedin:before {
  content: "\e904";
}
.cs-copy-link:before {
  content: "\e905";
}
.cs-instagram:before {
  content: "\e900";
}
.cs-twitter:before {
  content: "\e901";
}
.cs-facebook-circle:before {
  content: "\e902";
}
